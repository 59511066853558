import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const SdsMain = () => {
  return (    
    <div className="cs-header d-flex align-items-end position-relative">
		<div className="top-header-symbol position-absolute d-sm-block d-none">
            <StaticImage src="../../../images/case-studies/social-delivery-system/top-header-bg.png" alt="Raaven header symbol" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1000} height={341}/>
		</div>
		<div className="bottom-header-symbol position-absolute">
            <StaticImage src="../../../images/case-studies/social-delivery-system/bottom-header-bg.png" alt="Raaven header symbol bottom" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1510}/>
		</div>        
        <Container>
            <Row className="justify-content-center">
                <Col lg={7} md={10} className="position-relative">
                    <div className="hero-image text-center">
                        <StaticImage src="../../../images/case-studies/social-delivery-system/raaven-hero-image.png" alt="Raaven Social Delivery System Hero Image" placeholder="blurred" className="img-fluid" width={2011} height={1784}/>
                    </div>
                </Col>
            </Row>
        </Container>       
    </div>
  )
}

export default SdsMain
