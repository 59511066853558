import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const SdsOverview = () => {
  return (
    <section className="raaaven-overview pt-0 position-relative">
		<div className="raaven-logo-faded position-absolute">
            <StaticImage src="../../../images/case-studies/social-delivery-system/raaven-logo-faded.png" alt="Raaven Logo cut" placeholder="blurred" className="img-fluid" width={625} height={219}/>
		</div>        
        <Container>
            <Row className="align-items-center">
                <Col lg={5} className="text-center text-lg-left">
                    <StaticImage src="../../../images/case-studies/social-delivery-system/raaven-overview-image.png" alt="Raaven Social Delivery System Overview" placeholder="blurred" className="img-fluid" width={552} height={853}/>
                </Col>
                <Col lg={6} className="offset-lg-1">
                    <h2 className="sub-title">Overview</h2>
                    <p className="cs-sub-text">Raaven is an app meant to help people who are in need of sending packages to their dear ones living in faraway places. The app aims at bringing travelers and senders together at a single point to facilitate an easy transfer of things.</p>
                    <p className="cs-sub-text mb-0">On raaven, people who want to send packages across can find travelers who are willing to carry packages on their behalf. We named the app after the bird Raven, which are also referred to as messenger or carrier pigeons, believed to be used as carriers ages back.</p>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default SdsOverview