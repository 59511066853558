import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const SdsRewardSystem = () => {
  return (
    <section className="rewards-system">        
        <Container>
            <Row className="justify-content-center">
                <Col lg={10} className="text-center">
                    <h2 className="sub-title">Raaven reward system</h2>
                    <p className="cs-sub-text">The raaven reward points system intends to motivate the carriers to keep using the app by rewarding them with NFTs that can be redeemed, thereby facilitating more engagement in the app, after each succesful delivery the carrier would receive a unique NFT reward, minted with etherum, which is part of a bigger collection of NFT’s called “Cryptoplanets” and the carriers could collecte a unique one as long as they does deliveries.</p>
                    <StaticImage src="../../../images/case-studies/social-delivery-system/reward-system.png" alt="Raavan Reward System | WowMakers" placeholder="blurred" className="img-fluid mt-5 pt-5" width={1142} height={938}/>
                </Col>               
            </Row>
        </Container>
    </section>
  )
}

export default SdsRewardSystem