import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const SdsIdea = () => {
  return (
    <section className="raaven-idea d-flex align-items-end">
        <Container>
            <Row className="justify-content-center">
                <Col lg={11} className="text-center">
                    <h2>The idea behind <span>raaven</span> came to fruition when one of our team members noticed a WhatsApp status. </h2>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default SdsIdea
