import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import SdsMoreCs from "../../components/case-studies/social-delivery-system/sdsMoreCs"
import DirectContact from "../../components/global/directContact"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/social-delivery-system.scss"

import csSdsFeaturedImage from "../../../static/case-studies/electric-car-commute-app/rivian-electric-car-commute-app-featured-img.jpg"
import SdsMain from "../../components/case-studies/social-delivery-system/sdsMain"
import SdsInfo from "../../components/case-studies/social-delivery-system/sdsInfo"
import SdsIdea from "../../components/case-studies/social-delivery-system/sdsIdea"
import SdsIdeaFullscreen from "../../components/case-studies/social-delivery-system/sdsIdeaFullscreen"
import SdsOverview from "../../components/case-studies/social-delivery-system/sdsOverview"
import SdsEarn from "../../components/case-studies/social-delivery-system/sdsEarn"
import SdsSendingPackages from "../../components/case-studies/social-delivery-system/sdsSendingPackages"
import SdsCarrying from "../../components/case-studies/social-delivery-system/sdsCarrying"
import SdsMakeOffer from "../../components/case-studies/social-delivery-system/sdsMakeOffer"
import SdsRewardSystem from "../../components/case-studies/social-delivery-system/sdsRewardSystem"
import SdsBottomFullscreen from "../../components/case-studies/social-delivery-system/sdsBottomFullscreen"


export const Head = () => (
  <>
    <SeoHead
      ogImage={csSdsFeaturedImage}
      title="Raaven Case Study: Building a Community for Easy Package Delivery | UX/UI Design | Logistics"
      description="Raaven is an innovative app that connects travelers and package senders for an easy transfer of things. Find out how Raaven is revolutionizing the way we send packages in this case study."
    />
  </> 
)

const SocialDeliverySystem = () => {
    return (
      <Layout>
        <Seo
          bid="cs-raaven-app"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <SdsMain />
        <SdsInfo />
        <SdsIdea />
        <SdsIdeaFullscreen />
        <SdsOverview />
        <SdsEarn />
        <SdsSendingPackages />
        <SdsCarrying />
        <SdsMakeOffer />
        <SdsRewardSystem />
        <SdsBottomFullscreen />
        <SdsMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default SocialDeliverySystem
