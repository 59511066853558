import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const SdsEarn = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/social-delivery-system/earn-with-raaven.jpg" alt="Earn with Raaven Social Delivery System  | WowMakers" placeholder="blurred" className="img-fluid" width={1440} height={1177}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default SdsEarn