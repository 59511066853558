import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const SdsCarrying = () => {
  return (
    <section className="carrying-raaven">        
        <Container>
            <Row className="justify-content-center">
                <Col lg={9} className="text-center">
                    <h2 className="sub-title text-white">Carrying for Raaven</h2>
                    <p className="cs-sub-text text-white">Carriers are the engine behind this platform, they’re the ones who make this whole thing running when you’re a carrier on Raaven, you could “bid” on different gigs posted by senders and, If they find you suitable, you’ll land on a gig and voila you’ll be a “raaven”</p>
                </Col>
                <Col lg={12} className="mt-5 pt-md-5">
                    <StaticImage src="../../../images/case-studies/social-delivery-system/carrying.png" alt="Carrying for Raaven | WowMakers" placeholder="blurred" className="img-fluid" width={1571} height={1182}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default SdsCarrying