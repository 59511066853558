import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const SdsSendingPackages = () => {
  return (
    <section className="sending-packagess">        
        <Container>
            <Row className="justify-content-center">
                <Col lg={9} className="text-center">
                    <h2 className="sub-title">Sending packages with Raaven</h2>
                    <p className="cs-sub-text">The raaven reward points system intends to motivate the carriers to keep using the app by rewarding them with NFTs that can be redeemed, thereby facilitating more engagement in the app</p>
                </Col>
                <Col lg={12} className="mt-5 pt-md-5">
                    <StaticImage src="../../../images/case-studies/social-delivery-system/sending-packages.png" alt="Sending Packages with Raaven | WowMakers" placeholder="blurred" className="img-fluid" width={1223} height={2322}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default SdsSendingPackages